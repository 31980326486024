import { Component, OnInit } from '@angular/core';
import Amplify from 'aws-amplify';

import { UserService } from './core/services/user.service';

Amplify.configure({
  Auth: {
    region: 's-west-2',
    identityPoolRegion: 's-west-2',
    userPoolId: 'us-west-2_SCecA5w4P',
    userPoolWebClientId: '4fgb87p6rtfmlabqcpr4pnebaa',
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private userService: UserService) {}

  ngOnInit() {
    this.userService.populate();
  }
}
